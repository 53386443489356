import { Box, Grid } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import { BoxSettingsListElement } from '~/routes/AccountBoxSettings'
import type * as TYPES from './PanelAreYouSure.types'

const PanelAreYouSurePerksUI = ({
  panel,
  ModalOptions,
  perks,
  navigate,
}: TYPES.PanelAreYouSurePerksUIProps) => {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body>{panel.subhead}</Body>

      <Box as="ul" listStyleType="none" pb="4">
        {perks.slice(0, 4).map(({ children }, index) => {
          return (
            <BoxSettingsListElement key={index}>
              {children}
            </BoxSettingsListElement>
          )
        })}
        <BoxSettingsListElement>
          Meat delivered to your door, with free shipping
        </BoxSettingsListElement>
        <BoxSettingsListElement>
          Access to humanely raised meat with no antibiotics, ever.
        </BoxSettingsListElement>
        <BoxSettingsListElement>
          Member-only access to exclusive deals
        </BoxSettingsListElement>
      </Box>

      <Grid gap={rem(16)} gridTemplateColumns="1 fr">
        {ModalOptions.map((cancelOption) => {
          if ('urlDestination' in cancelOption) {
            const { text, urlDestination, ...props } = cancelOption
            return (
              <ModalItemSmall
                key={text}
                onClick={() => navigate(urlDestination)}
                {...props}
              >
                {text}
              </ModalItemSmall>
            )
          } else if ('onClick' in cancelOption) {
            const { text, onClick, ...props } = cancelOption
            return (
              <ModalItemSmall key={text} onClick={onClick} {...props}>
                {text}
              </ModalItemSmall>
            )
          }
          const typeGuard: never = cancelOption
          return typeGuard
        })}
      </Grid>
    </PanelLayout>
  )
}

export default PanelAreYouSurePerksUI
